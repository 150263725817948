@import "form";

$blue: #2196f3;
$height: 100px;



.mailjet-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $height;
    background-color: #e6f0f8;

    .container {
        width: 980px;
        margin: auto;
        position: relative;

        .mailjet-top-left {
            float: left;
            margin-top: 24px;

            a {
                display: inline-block;
                transform: scale(.9);
                transition: transform .2s ease-in-out;
                &:hover {
                    transform: scale(1);
                }
            }
        }

        .mailjet-top-right {
            position: absolute;
            top: 0;
            right: -22px;
            height: $height;
            left: 200px;
            ul {
                display: flex;
                justify-content: flex-end;
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    line-height: $height;
                    max-height: $height;
                    position: relative;


                    h1 {
                        margin: 0;
                        padding: 0;
                        max-width: 200px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-top: 50px;
                        transform: translateY(-50%);
                        color: $blue;

                        img {
                            margin: 0;
                            padding: 0;
                        }
                    }

                    &.user h1 {
                        max-width: 560px;
                    }



                    &.border-left::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 30px;
                        height: 42px;
                        width: 2px;
                        background-color: #c7d0d9;
                    }

                    a {
                        color: $blue;
                        display: block;
                        line-height: $height;
                        padding: 0 22px;

                        .fa {
                            font-size: 2.3rem;
                            position: relative;
                            top: 4px;
                        }
                    }


                    .admin {
                        font-family: 'Montserrat', sans-serif;
                        font-size: 1.5rem;
                        color: #38384a;
                        i {
                            font-size: 1.5rem;
                            margin-left: 5px;
                            margin-top: 2px;
                            top: 0;
                        }
                    }

                    &.percent {
                        & > div {
                            top: 50px;
                            transform: translateY(-50%);
                            margin-left: 20px;
                        }
                    }


                    &.admin-switch {
                        line-height: 1.5rem;
                        .select2 {
                            margin-top: 14px;
                            width: 220px;
                        }
                    }
                }
            }
        }
    }
}


.mailjet-top .slice {
    .bar, .fill {
        border-color: $blue !important;
    }
}

.mailjet {
  position: fixed;
  top: $height;
  left: 0;
  right: 0;
  bottom: 0;
}


// Customize select2
.admin-switch .select2 {
    &.select2-container--default .select2-selection--single {
        border-radius: 0;
        border: 0;
    }

    .select2-selection__arrow b {
        border-color: #38384a transparent transparent transparent;
        border-width: 7px 6px 0 6px;
        margin-left: -9px;
        margin-top: -3px;
    }

    &.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
        border-color: transparent transparent #38384a transparent;
        border-width: 0 6px 7px 6px;

    }

}



.mailjet-message {
    position: relative;
    top: 20%;
    border-top: 2px solid #e6f0f8;
    border-bottom: 2px solid #e6f0f8;
    width: 500px;
    margin: auto;
    padding: 50px;
}

.change-password .change-password-container {
    display: flex;
    justify-content: left;
    align-items: center;

    .right-part {
        margin-left: 70px;
        ul {
            padding: 0;
            li {
                padding: 3px 0;
                .fa {
                    margin-right: 15px;
                }
            }
        }
    }
}

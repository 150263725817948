


@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400italic,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300);
@font-face {
    font-family: Berlin;
    src: url(../font/Berlin.ttf);
    src: url(../font/Berlin.eot);
    src: url(../font/Berlin.otf);
    src: url(../font/Berlin.svg);
    src: url(../font/Berlin.woff);
    src: url(../font/Berlin.woff2);
}
/*============================================
    BASE
==============================================*/
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    display: table;
    font-family: 'Montserrat', sans-serif;
    background: white;
}
h2 {
    color: #2295F2;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.2;
}
a {
    font-family: 'Montserrat', sans-serif;
}
a:hover {
    text-decoration: underline;
}
.wrapper {
    max-width: 960px;
    margin: 0 auto;
    overflow:hidden;
    position: relative;
}


.error {
    font-size: 13px;
    color: red;
    text-align: center;
    margin-bottom: 10px;
}

h2.reinit {
    color: white;
    text-align: center;
    font-size: 14px;
}
/*============================================
    ANIMATION
==============================================*/
@keyframes mouseBlink {
    0%   {opacity: 1}
    50%  {opacity: 0}
    100% {opacity: 1}
}
/*============================================
    LOGIN SECTION
==============================================*/
/*The main container*/
.login-section {
    height: 100vh;
    width: 100%;
    background: url('../img/background.jpg') no-repeat center fixed;
    background-size: cover;
    position: relative;
    overflow: hidden;
}
.form-wrapper {
    max-width: 360px;
    margin: 10vh auto;
    position: relative;
    height: 90vh;
}
/*Logo*/
#titlelogo {
    display: block;
    margin: 0 auto 75px;
    max-width: 95%;
}
/*The form--------------*/
.loginform {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    width: 500px;
    box-shadow: -2px 0px 5px -2px #555, 2px 0px 5px -2px #555;
}
.inputs-wrapper {
    padding: 40px 22px 1px 22px;
    background-color: #FFF;
}
.loginform .group {
    position: relative;
    margin-bottom: 20px;
}
.loginform .group::before {
    content: '';
    position: absolute;
    width: calc(100% - 4px);
    left: 2px;
    top: 2px;
    height: 7px;
    background-color: #E5E5E5;
    z-index: 8;
    transition: height 0.3s;
}

.loginform .group.active::before {
    height: 58px;
}

.loginform .group input:focus::before {
    height: 58px;
}

.loginform .group::after {
    content: '';
    position: absolute;
    right: 0px;
    top: 0;
    width: 50px;
    height: 60px;
    z-index: 4;
    border-left: 2px solid #EBEBEB;
}
.loginform .group.user::after {
    background: url('../img/avatar.png') no-repeat center 22px;
}
.loginform .group.psw::after {
    background: url('../img/lock.png') no-repeat center 22px;
}
.loginform input {
    position: relative;
    z-index: 10;
    border-radius: 0;
    border: 2px solid #DADADA;
    width: 100%;
    height: 60px;
    padding: 25px 60px 15px 20px;
    color: #7C7D75;
    outline: none;
    background: transparent;
}
.loginform input + label {
    color: #7C7D75;
    font-weight: normal;
    position: absolute;
    top: 24px;
    left: 22px;
    transition: font-size 0.3s, top 0.3s, left 0.3s;
}

.loginform .group.active input + label {
    top: -15px;
    font-size: 11px;
    left: 10px;
    z-index: 11;

}


.loginform button {
    width: 100%;
    height: 55px;
    background: #39384A;
    border: 0;
    color: #FFF;
    text-transform: uppercase;
    font-weight: bold;
    border-left: 1px solid #606676;
    border-right: 1px solid #606676;
    outline: 0;
    position: relative;
}


/*End of the form--------------*/
.link-container {
    margin: 15px;
    text-align: center;
}
.link-container a {
    color: #FFF;
    font-size: 1.2rem;
}
/*To indicate the scroll*/
.mouse {
    position: absolute;
    bottom: 10vh;
    left: 50%;
    transform: translateX(-50%);
    animation: mouseBlink 3s infinite;
}
.mouse.hidden {
    /*TODO*/
}
.logo-seva {
    position: absolute;
    right: 80px;
    bottom: 50px;
    width: 150px;
}
.logo-seva img {
    max-width: 100%;
}
@media (max-width:500px) {
    .mouse {
        display: none;
    }
}
/*============================================
    MOTIV SECTION
==============================================*/
.motiv-section {
    padding: 50px 10px 80px;
    text-align: center;
}
.bubble {
    width: 25%;
    margin: 0 3%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
}
.motiv-section .wrapper .bubble:first-child {
    width: calc(25% + 20px);
}
.bubble p {
    color: #998C8C;
    font-size: 13px;
}
.bg-bubble {
    position: relative;
    width: 100%;
    display: inline-block;
}
.bg-bubble img {
    max-width: 181px;
    width: 100%;
}
.bubble-1 img {
    max-width: 202px;
}
@media (max-width:500px) {
    .bubble, .motiv-section .wrapper .bubble:first-child {
        width: 100%;
        padding: 40px;
    }
    .bg-bubble img {
        width: 80%;
    }

}
/*============================================
    SALES POINTS SECTION
==============================================*/
.sales {
    padding: 30px 10px;
    overflow: hidden;
}
.sales-white {
    padding: 50px 10px;
    overflow: hidden;
}
.sales-dark {
    background: #31364C;
    color: #FFF;
}
.sales-dark .wrapper {
    display: flex;
    align-items: center;
}
.sales-dark h2 {
    color: #88CBFF;
}
.sales-content, .sales-img {
    width: 49%;
    display: inline-block;
    vertical-align: middle;
}
.sales-content {
    font-family: 'Source Sans Pro', sans-serif;
}
.sales-content h2 span {
    display: block;
}
.sales-content h3 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
}
.sales-content p {
    font-size: 1.6rem;
}
.sales-img {
    text-align: center;
}
.sales-dark .sales-content {

}
.sales-dark .sales-img {
}
.sales-white .sales-img {
    text-align: left;
}
.sales-img img {
    max-width: 100%;
}
@media (max-width:500px) {
    .sales-dark .wrapper {
        flex-flow: column-reverse wrap;
    }

    .sales-content, .sales-img {
        width: 100%;
    }
    .sales-content h2 {
        text-align: center;
        margin: 30px 0;
    }

}

/*============================================
    DEMO SECTION
==============================================*/
.demo-section {
    text-align: center;
    padding: 75px 10px 50px;
}
.demo-section a {
    display: inline-block;
    width: 260px;
    height: 56px;
    border-radius: 28px;
    background-color: #2196F3;
    text-transform: uppercase;
    font-size: 1.4rem;
    text-align: center;
    padding-top: 19px;
    color: white;
    position: relative;
}
.demo-section a:hover {
    text-decoration: none;
    top: -2px;
    background-color: #41A4F2;
}
.demo-section img {
    display: block;
    width: 175px;
    margin: 30px auto 0;
}

/*============================================
    FOOTER
==============================================*/
/*Footer Top*/
.footer-top {
    color: white;
    background-color: #2196F3;
    padding: 30px 10px 80px;
    font-family: 'Roboto', sans-serif;
}
.footer-top h2 {
    color: white;
    margin-bottom: 25px;
}
.footer-top .contact, .footer-top .find-us {
    display: inline-block;
    vertical-align: top;
}
.footer-top .contact {
    width: 25%;
    margin-left: 10%;
}
.footer-top .contact p {
    padding-left: 50px;
    position: relative;
}
.footer-top .contact p:before {
    content: '';
    background: url('../img/envelope.png') no-repeat;
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    left: 0px;
}

.footer-top .find-us {
    width: 64%;
}

.find-us div {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 50px;
}

.find-us div:last-child {
    margin-left: 100px;
}

.find-us div:before {
    content: '';
    background: url('../img/marker.png') no-repeat;
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    left: 0px;
}
.find-us p {
    margin-bottom: 0;
}

/*Footer bottom*/
.footer-bottom {
    color: #5EB3F6;
    background-color: #006DC2;
    padding: 15px 10px;
    font-size: 13px;
}
.footer-bottom p {
    margin: 0;
}
.footer-bottom .wrapper div {
    display: inline-block;
}
.footer-bottom .wrapper div:first-child {
    width: 70%;
}
.footer-bottom .wrapper div:last-child {
    width: 29%;
}
.footer-bottom a {
    color: #5EB3F6;
    margin-left: 20px;

}

@media (max-width:500px) {
    .footer-top .contact, .footer-top .find-us {
        width: 100%;
        margin-left: 0;
        padding-left: 10px;
    }

    .find-us div:last-child {
        margin: 20px 0 0;
    }

    .footer-bottom .wrapper div:first-child, .footer-bottom .wrapper div:last-child  {
        width: 100%;
        text-align: center;
    }

    .footer-bottom .wrapper div:last-child {
        margin-top: 15px;
    }
}

@import "mailjet";


.flashes {
  background-color: white;
  margin: 0;
  padding: 5px;
  list-style-type: none;
  ul {
    list-style-type: none;
    .valid {
      color: green;
      position: relative;
      &::before {
        position: absolute;
        content: "✓";
        left: -15px;
      }
    }
    .no-valid {
      color: red;
      padding: 0;
      position: relative;
      &::before {
        position: absolute;
        content: "✗";
        left: -15px;
      }
    }
  }
}



.info {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #2196f3;
    font-size: 1.8rem;
    text-align: center;
    text-transform: uppercase;
    margin: 10px;
    display: block;
}

.info-content {
    font-family: 'Montserrat', sans-serif;
    color: #7d7d74;
    font-size: 1.4rem;
    text-align: center;
    margin: 10px;
    display: block;
}

.is-error {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #2196f3;
    font-size: 1.4rem;
    text-align: center;
    margin: 10px;
    display: block;
}


.valid-password {
    list-style-type: none;
    font-family: 'Montserrat', sans-serif;
    color: #7d7d74;
    font-size: 1.4rem;

    li {
        min-height: 20px;

        .fa {
            min-width: 20px;
        }


        .fa-check {
            color: #96c827;
            font-size: 1.8rem;

            & + span {
                color: #96c827;
            }
        }
        .fa-times {
            color: #e22c3d;
            font-size: 1.8rem;

            & + span {
                color: #e22c3d;
            }
        }
    }
}


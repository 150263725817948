
.input-field {
  position: relative;

  input {
    margin: 30px 0 0;
    width: 335px;
    background-color: #e6f0f8;
    border: 0px;
    line-height: 50px;
    padding-left: 10px;
  }

  input + label {
    position: absolute;
    top: 15px;
    left: 0;
    font-size: 1rem;
    transition: all .1s ease-in-out;
    color: #7d7d74;
  }

  input.empty + label {
    position: absolute;
    font-size: 1.3rem;
    top: 30px;
    left: 15px;
    line-height: 50px;
  }
}

.change {
  border-radius: 50px;
  margin-top: 30px;
  height: 50px;
  width: 160px;
  color: white;
  border: 0;
  background-color: #2196f3;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    background-color: darken(#2196f3, 10%);
  }
}


